div.footer_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    padding: 1%;
    height: 5vh;
    width: 100%;
}

div.footer_container p.texto_footer {
    font-family: 'Montserrat';
    font-weight: 300;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
}

/*Responsive*/

@media only screen and (max-width: 768px) {
    div.footer_container {
        height: 8vh;    
    }
  }

@media only screen and (max-width: 600px) {
    div.footer_container {
        height: 8vh;    
    }
  }
  