/*Tarjetas*/

.cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 95vh;
   
}

.container_tarjetas {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.tarjeta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 3%;
    margin: 3%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.tarjeta .delete_button {
    display: flex;
    align-self: end;
}

div.container_tarjetas div.tarjeta_consulta {
    width: 25%;
    display: contents;
}

.tarjeta .texto {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.tarjeta .texto h2 {
    font-family: 'Montserrat';
    margin-bottom: 2%;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}

.tarjeta .texto h3 {
    font-family: 'Montserrrat';
    margin-bottom: 2%;
    color: #000000;
    font-style: italic;
    font-size: 18px;
    margin-bottom: 2%;
    text-align: center;
}

.tarjeta .texto p {
    font-family: "Montserrat";
    margin-bottom: 2%;
    font-size: 12px;
    color: #000000;
    text-align: center;
}

.tarjeta .texto p.cuerpo_consulta {
    display: flex;
    flex-direction: column;
}

.tarjeta a.mailto {
    font-family: "Montserrat";
    font-size: 12px;
    color: #000000;
    text-align: center;
    margin-top: 2%;
}

div.ver_mas {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

div.ver_mas button.ver_todos {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    background-color: #ffffff;
    border-style: none;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

div.ver_mas h2 {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
}

/*Indice de notas*/

div.cards div.busqueda_notas {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8%;
}

div.cards div.busqueda_notas span.campo_boton {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8%;
}

div.busqueda_notas span.campo_boton input {
    padding: 12px 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 5%;
}

div.busqueda_notas span.campo_boton button {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #000000;
    cursor: pointer;
}


div.cards div.busqueda_notas a.ver_notas {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 18px;
    color: #000;
    text-align: center;
    cursor: pointer;
}

div.cards div.busqueda_notas ul {
    font-family: "Montserrat";
    font-size: 15px;
    color: #000;
    text-align: center;
    cursor: pointer;
    list-style: none;
}

div.cards div.busqueda_notas a.cerrar_notas {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 18px;
    color: #000;
    text-align: center;
    cursor: pointer;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
    .container_tarjetas {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
    }
    .cards {
        display: flex;
        flex-direction: column;
        height: 75vh;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position-y: 0vh;
        background-position-x: 2vw;
        margin: 5% 0 5% 0;
    }
    div.cards div.busqueda_notas {
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-right: 0;
    }
}

@media only screen and (max-width: 768px) {
    .container_tarjetas {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
    }
    .tarjeta img {
        width: 100%;
        max-width: 40vw;
    }
    div.cards div.busqueda_notas {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        padding: 15%;
        margin-right: 0;
    }
}

@media only screen and (max-width: 600px) {
    .tarjeta img {
        width: 100%;
        max-width: 100vw;
    }
    .cards {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        min-height: 87vh;
        margin-top: 5%;
    }
    .container_tarjetas {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: auto;
        overflow-y: scroll;
    }
    .cards h2.titulo_accede {
        font-family: "FreeSansBoldOblique";
        color: #ffffff;
        background-color: #D80191;
        margin-bottom: 25%;
        text-align: center;
        width: 90%;
        border-radius: 25px;
    }
    div.ver_mas {
        margin-top: 0;
    }
    div.cards div.busqueda_notas {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        align-self: center;
        padding: 15%;
    }
}