div.TerceraVista_container {
  display: flex;
  flex-direction: row;
  background: #000
    url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/AdobeStock_281831300.jpeg)
    no-repeat center center fixed;
  background-position-y: -4vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 84vh;
}

div.TerceraVista_container div.contenido {
  width: 30%;
  align-self: center;
  margin-left: 4%;
  padding: 1%;
  background-color: #00000040;
  border-radius: 5px;
}

div.TerceraVista_container h1 {
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom: 5%;
  color: #ffffff;
}

div.TerceraVista_container h3 {
  font-family: "Montserrat";
  font-weight: 300;
  color: #ffffff;
}

div.TerceraVista_container a {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 100px 100px 100px 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-color: #000000;
  cursor: pointer;
  width: 50%;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
  div.TerceraVista_container {
    height: 90vh;
  }
  div.TerceraVista_container div.contenido {
    width: 45%;
  }
}

@media only screen and (max-width: 768px) {
  div.TerceraVista_container {
    height: 82vh;
    background-position-x: -116vw;
  }
  div.TerceraVista_container div.contenido {
    width: 45%;
  }
  div.TerceraVista_container h1,
  div.TerceraVista_container h3 {
    color: #fff;
  }
}

@media only screen and (max-width: 600px) {
  div.TerceraVista_container {
    height: 84vh;
    background-position-y: -2vh;
    background-position-x: -210vw;
  }
  div.TerceraVista_container div.contenido {
    width: 90%;
  }
}
