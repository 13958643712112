div.contacto_container {
  display: flex;
  flex-direction: row;
  background: #000
    url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/contacto3.png)
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 95vh;
}

div.contacto_container div.contenido {
  width: 30%;
  align-self: center;
  margin-left: 4%;
}

div.contenido span.texto_contacto h1 {
  font-family: "Montserrat";
  font-weight: 500;
  color: #fff;
  margin-bottom: 3%;
}

div.contenido span.texto_contacto h3 {
  font-family: "Montserrat";
  font-weight: 300;
  margin-bottom: 3%;
  color: #fff;
}

div.contenido form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

div.contenido form input,
div.contenido form textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

div.contenido form button {
  width: 100%;
  background-color: #4caf50;
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 18px;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
}

div.contenido form button:hover {
  background-color: #3e8e41;
  border: #3e8e41;
  color: #000;
  transition: 0.5s;
  cursor: pointer;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
  div.contacto_container div.contenido {
    width: 50%;
    margin-top: 15%;
  }
}

@media only screen and (max-width: 768px) {
  div.contacto_container {
    height: 92vh;
  }

  div.contacto_container div.contenido {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  div.contacto_container {
    height: 92vh;
  }
  div.contacto_container div.contenido {
    width: 90%;
  }
}
