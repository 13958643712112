div.tecnologias_container {
    background-image: url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/SERVICIOS.jpg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 270vh;
}

div.tecnologias_container div.contenido {
    width: 100%;
    align-self: center;
}

div.container_tarjetas div.contenido_movil {
    /* opacity: 0; */
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

div.container_tarjetas div.contenido_web {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* div.container_tarjetas div.contenido_web 
    span.frontend, 
    span.backend,
    span.databases,
    span.infraestructura  {
    opacity: 0;
} */

div.tecnologias_container h1.titulo_seccion {
    font-family: "Montserrat";
    font-weight: 500;
    margin-bottom: 2%;
}

div.tecnologias_container h1.titulo_plataforma {
    font-family: "Montserrat";
    font-weight: 500;
    margin: 5%;
    color: rgba(128, 128, 128, 0.57);
}

div.container_tarjetas h4.titulo {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 2%;
    color: gray;
}

div.tecnologias_container h3 {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 30px;
}

div.tecnologias_container div.contenido div.container_tarjetas {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

div.tecnologias_container div.contenido div.container_tarjetas span.fila {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
}

.reveal {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: all 2s ease;
}

.active {
    transform: translateY(0px);
    opacity: 1;
}

/*Tarjeta*/

div.tarjeta {
    padding: 2%;
    align-self: flex-end;
}

div.tarjeta div.texto {
    text-align: center;
}

div.tarjeta div.texto p {
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 20px;
    color: #fff;
}

/*Responsive*/

@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 768px) {
    div.tecnologias_container {
        height: 220vh;
    }
}

@media only screen and (max-width: 600px) {
    div.tecnologias_container {
        height: 315vh;
    }
    div.tecnologias_container div.contenido {
        width: 100%;
        text-align: center;
        margin-top: 25%;
        margin-left: 0%;
    }
    div.tecnologias_container div.contenido div.container_tarjetas span.fila {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 5%;
        justify-content: center;
    }
    div.tecnologias_container h3 {
        width: 100%;
        margin-bottom: 25%;
    }
    div.container_tarjetas div.contenido_movil {
        flex-direction: column;
    }
    div.tecnologias_container h1.titulo_seccion {
        color: #fff;
        margin-top: 20%;
    }
}