div.trabajo_wedrim_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #000
    url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/TRABAJA_CON_NOSOTROS.jpg)
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 95vh;
}

div.trabajo_wedrim_container div.contenido {
  width: 30%;
  align-self: center;
  margin-left: 4%;
  margin-bottom: 5%;
  padding: 1%;
  background-color: #00000040;
  border-radius: 5px;
}

div.trabajo_wedrim_container h1 {
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom: 2%;
  color: #ffffff;
}

div.trabajo_wedrim_container h3 {
  font-family: "Montserrat";
  font-weight: 300;
  margin-bottom: 5%;
  color: #ffffff;
}

div.trabajo_wedrim_container h3.titulo_input {
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom: 5%;
}

div.trabajo_wedrim_container div.contenido .input_msj {
  width: 100%;
  margin-bottom: 5%;
  color: #ffffff;
  font-weight: bolder;
}

div.trabajo_wedrim_container div.contenido .cv_btn {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 300;
  width: 50%;
  color: #ffffff;
  background-color: transparent;
  border-style: solid;
  border-radius: 100px 100px 100px 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-color: #ffffff;
  cursor: pointer;
  text-align: center;
}

div.trabajo_wedrim_container Button {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 100px 100px 100px 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-color: #000000;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

div.trabajo_wedrim_container div.sobre_btn {
  display: flex;
  flex-direction: column;
  margin: 10% 0 0 2%;
  align-items: center;
}

div.sobre_btn h4 {
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom: 20%;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
  div.trabajo_wedrim_container div.contenido {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  div.trabajo_wedrim_container {
    height: 92vh;
  }

  div.trabajo_wedrim_container div.contenido {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  div.trabajo_wedrim_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 92vh;
  }
  div.trabajo_wedrim_container div.contenido {
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-left: 0%;
  }
  div.trabajo_wedrim_container div.sobre_btn {
    display: none;
  }

  div.trabajo_wedrim_container div.contenido .cv_btn {
    width: 75%;
  }
  div.trabajo_wedrim_container div.contenido .input_msj {
    width: 80%;
    text-align: center;
  }
}
