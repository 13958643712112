.gotop_Btn{
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: fixed;
    padding: 10px;
    border-radius: 88%;
    bottom: 9%;
    right: 5%;
    background-color: #ffffff;
    cursor: pointer;
    z-index: 10;
}


.gotop_Btn .arrow_up {
    font-size: 20px;
}