div.TerceraVista_container {
  display: flex;
  flex-direction: row;
  height: 84vh;
  background: #000
    url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/plat_asincronica.jpg)
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

div.TerceraVista_container::before {
  content: "";
  background-image: linear-gradient(to bottom, #25252530, #25252530);
  width: 100%;
  height: 84vh;
  position: absolute;
}

div.TerceraVista_container div.contenido {
  width: 30%;
  align-self: center;
  margin-left: 4%;
  padding: 1%;
  background-color: #00000040;
  border-radius: 5px;
  z-index: 10;
}

div.TerceraVista_container h1 {
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom: 5%;
  color: #ffffff;
}

div.TerceraVista_container h3 {
  font-family: "Montserrat";
  font-weight: 300;
  color: #ffffff;
}

div.TerceraVista_container a {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 100px 100px 100px 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-color: #000000;
  cursor: pointer;
  width: 50%;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
  div.TerceraVista_container {
    height: 86vh;
  }

  div.TerceraVista_container div.contenido {
    width: 45%;
  }

  div.TerceraVista_container::before {
    height: 86vh;
  }
}

@media only screen and (max-width: 768px) {
  div.TerceraVista_container {
    height: 86vh;
  }

  div.TerceraVista_container div.contenido {
    width: 45%;
  }

  div.TerceraVista_container::before {
    height: 86vh;
  }
}

@media only screen and (max-width: 600px) {
  div.TerceraVista_container {
    height: 85vh;
    background-position-x: -120vw;
  }
  div.TerceraVista_container::before {
    height: 85vh;
  }
  div.TerceraVista_container div.contenido {
    width: 90%;
  }
}
