div.clientes_container {
    background-image: url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/SERVICIOS.jpg);
    display: flex;
    flex-direction: row;
    height: 95vh;
}

div.clientes_container div.contenido {
    width: 80vw;
    align-self: center;
    margin-top: 15%;
    margin-left: 10%;
    overflow: hidden;
}

div.clientes_container div.contenido div.container_tarjetas_movil {
    display: none;
}

div.clientes_container h1 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 2%;
    color: #ffffff;
}

div.clientes_container h3 {
    font-family: 'Montserrat';
    font-weight: 300;
    color: #ffffff;
}

div.clientes_container a {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #000000;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

div.clientes_container div.contenido div.container_tarjetas {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 80vw;
    overflow: hidden;
}

div.clientes_container div.contenido div.container_tarjetas span.fila {
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
}

/*Tarjeta*/

div.tarjeta {
    padding: 2%;
    text-align-last: center;
    align-self: center;
}

div.tarjeta div.texto {
    text-align: center;
}

div.tarjeta div.texto p {
    font-family: 'Montserrat';
    font-weight: 300;
    color: #ffffff;
}

/*Slider*/

div.container_tarjetas ul.marquee_content_left {
    list-style: none;
    animation: scrolling-left 25s linear infinite;
}

@keyframes scrolling-left {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-60vw);
    }
}

div.container_tarjetas ul.marquee_content_left li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20vw;
    flex-shrink: 0;
    white-space: nowrap;
}

div.container_tarjetas ul.marquee_content_right {
    list-style: none;
    animation: scrolling-right 25s linear infinite;
}

@keyframes scrolling-right {
    0% {
        transform: translateX(-60vw);
    }
    100% {
        transform: translateX(0);
    }
}

div.container_tarjetas ul.marquee_content_right li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20vw;
    flex-shrink: 0;
    white-space: nowrap;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
    div.clientes_container {
        height: 95vh;
        background-position-y: 306vh;
        background-position-x: 160vh;
    }
    div.clientes_container div.contenido {
        width: 90%;
    }

    div.tarjeta {
        padding: 1%;
    }
}

@media only screen and (max-width: 768px) {
    div.clientes_container {
        height: 92vh;
        background-position-y: 158vh;
        background-position-x: 196vw;
    }

    div.clientes_container div.contenido {
        width: 90%;
    }

    div.tarjeta {
        padding: 1%;
    }
}

@media only screen and (max-width: 600px) {
    div.clientes_container {
        height: 92vh;
        margin-left: 0%;
    }
    div.clientes_container div.contenido {
        width: 100%;
        text-align: center;
        align-self: normal;
        margin-top: 25%;
        margin-left: 0%;
        overflow: scroll;
    }
    div.clientes_container div.contenido div.container_tarjetas {
        display: flex;
    }
    div.clientes_container div.contenido div.container_tarjetas_movil {
        display: flex;
        flex-direction: column;
    }
    div.clientes_container div.contenido div.container_tarjetas span.fila {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 5%;
        justify-content: center;
    }
    div.container_tarjetas ul.marquee_content_right, div.container_tarjetas ul.marquee_content_left {
        animation: none !important;
    }
    div.clientes_container div.contenido div.container_tarjetas {
        width: 100%;
    }

    div.tarjeta{
        padding: 1%;
        margin: 10% 0 10% 0;

    }
}