div.legales_container {
    background-image:  url(http://localhost:8000/legales.png);
    display: flex;
    flex-direction: row;
    height: 95vh;
}

div.legales_container div.contenido {
    width: 100%;
    align-self: center;
    margin-top: 10%;
    margin-left: 4%;
}

div.legales_container h1 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 2%;
}

div.legales_container h2 {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 2%;
}

div.legales_container h3 {
    font-family: 'Montserrat';
    font-weight: 300;
    width: 30%;
}

div.legales_container a {
    Font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #000000;
    cursor: pointer;
    width: 100%;
    text-align: center;
}


div.legales_container div.contenido div.container_tarjetas {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

div.legales_container div.contenido div.container_tarjetas span.fila {
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
}

/*Tarjeta*/
div.tarjeta {
    padding: 2%;
}

div.tarjeta div.texto {
    text-align: center;
}

div.tarjeta div.texto p {
    font-family: 'Montserrat';
    font-weight: 300;
}




/*Responsive*/

@media only screen and (max-width: 600px) {

    div.legales_container {
        height: 110vh;
    }

    div.legales_container div.contenido {
        width: 90%;
        text-align: center;
        margin-top: 25%;
    }
    div.legales_container div.contenido div.container_tarjetas span.fila {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 5%;
        justify-content: center;
    }

    div.legales_container h3 {
        width: 100%;
        margin-bottom: 25%;
    }
}