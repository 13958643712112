div.sobre_btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 12%;
    height: 20vh;
    position: absolute;
}

div.sobre_btn h4 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin-bottom: 1%;
    color: #ffffff;
    background-color: #111;
    padding: 2%;
}

div.sobre_btn button {
  color: #ffffff;
  background-color: #111;
  padding: 2%;
  border-color: #111;
  width: max-content;
  cursor: pointer;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
  div.sobre_btn {
    margin: 3% 0 0 3%;

}
  }

@media only screen and (max-width: 768px) {
  div.sobre_btn {
    width: 10%;
    height: 20vh;
    margin-left: 5%;

}
  }
  
  @media only screen and (max-width: 600px) {
    div.sobre_btn {
        display: none;
    }
  }