div.corporaciones_container {
  display: flex;
  flex-direction: row;
  height: 95vh;
  background: #000
    url(https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/Servicios_GRANDES_EMPRESAS-min.jpeg)
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

div.corporaciones_container div.contenido {
  width: 30%;
  align-self: center;
  margin: 6% 0 0 4%;
  padding: 1%;
  background-color: #00000040;
  border-radius: 5px;
}

div.corporaciones_container h1 {
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom: 2%;
  color: #ffffff;
}

div.corporaciones_container h3 {
  font-family: "Montserrat";
  font-weight: 300;
  color: #ffffff;
}

div.corporaciones_container a {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 100px 100px 100px 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-color: #000000;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

/*Responsive*/

@media only screen and (max-width: 992px) {
  div.corporaciones_container div.contenido {
    width: 60%;
    margin-top: 20%;
  }
}

@media only screen and (max-width: 768px) {
  div.corporaciones_container {
    height: 170vh;
  }
  div.corporaciones_container div.contenido {
    width: 60%;
    margin-top: 20%;
  }
}

@media only screen and (max-width: 600px) {
  div.corporaciones_container {
    height: 170vh;
  }

  div.corporaciones_container div.contenido {
    width: 90%;
    margin-left: 4%;
    z-index: 10;
  }
}
